<template>
  <div class="modal-retail">
    <div class="popup__box__close" @click="$emit('close-modal',false)">
      <IconClosePopup/>
    </div>
    <DataStats :data="retailerEpackages[selectedRetailer.id]" :selected-retailer="selectedRetailer" @updateData="updateData($event)"/>
  </div>
</template>

<script>
import DataStats from "./DataStats";
import {mapMutations, mapState} from "vuex";
import BubbleMapData from "../../../services/data/maps/BubbleMapData";
import IconClosePopup from "../../ui/icons/IconClosePopup";
import {getCurrentDateRange} from "../../../services/Helpers/HelperTime";

export default {
  name: "ModalRetailTable",
  components: {IconClosePopup, DataStats},
  props: ['selectedRetailer'],
  data() {
    return {
      activeModal: false,
      loading: true,
      licensesDefault: [],
      retailersDefault: [],
      productsCount: 0,
      epackagesCount: 0,
      usersCount: 0,
      userLicensesID: [],
      userRetailersID: [],
      defaultFilters: {},
      filters: {
        licenses: [],
        retailers: [],
        contentType: 'minisite',
        range: getCurrentDateRange(),
      },
      mapStats: {
        latlong: BubbleMapData.latlong,
        data: [],
      },
      checksStats: {
        datasets: [],
        labels: [],
      },
      IPStats: {
        latlong: {},
        data: [],
      },
      retailerStats: {
        labels: ['all'],
        datasets: [],
      },
      timeStats: {
        labels: ['all'],
        datasets: [],
      },
      licenseStats: {
        labels: ['all'],
        datasets: [],
      },
      pieStats: {
        labels: [],
        datasets: [],
      },
      browserStats: {
        labels: [],
        datasets: [],
      },
      osStats: {
        labels: [],
        datasets: [],
      },
      devicesStats: {
        labels: [],
        datasets: [],
      },
      platformsStats: {
        labels: [],
        datasets: [],
      },
      impressionsCount: 0,
      visibleList: [],
      popularProductsStats: [],
      dataStats: [],
      infoTiles: [
        {
          color: 'success',
          value: '0',
          text: 'E-packages',
          icon: '',
        },
        {
          color: 'danger',
          value: "0",
          text: 'Licenses',
          icon: '',
        },
        {
          color: 'info',
          value: "0",
          text: 'Retailers connected',
          icon: '',
        },
      ],
      progressMax: 0,
    };
  },
  computed: {
    ...mapState(['retailerEpackages']),
  },
  methods: {
    ...mapMutations(['SET_RETAIL_EPACKAGES']),
    updateData(data) {
      let retailerData = {...this.retailerEpackages}
      retailerData[this.selectedRetailer.id] = data
      this.SET_RETAIL_EPACKAGES(retailerData)
    }
  }
};
</script>

<style scoped lang="scss">
.row {
  margin-top: 40px !important;
}

.modal-retail {
  width: 100%;
  height: 100%;
  z-index: 99999;
  position: fixed;
  top: 15px;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
}

.popup__box__close {
  position: absolute;
  left: 0;
  height: 60px;
  right: 0;
  top: 15px;
  bottom: auto;
  width: fit-content;
  margin-left: auto;
  margin-right: 15px;
}
</style>
