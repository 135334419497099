<template>

  <div class="row">
    <div class="flex md12 xs12">

      <vac-card :title="$t('Problems')">

        <Actions :export-to-excel="exportToExcel"/>

        <div class="row">
          <div class="flex xs12">
            <h1 class="title-table">Total {{ totalCount }}</h1>
          </div>
          <div class="flex xs12">
            <div class="cards__percentage">
<!--              <VacChart :data="calcChartData(selectedRetailer)" :options="chartOptions" type="donut"/>-->
              <Doughnut
                :dataset-id-key="'label'"
                :height="70"
                :chart-options="chartOption"
                :chart-data="calcChartData(selectedRetailer)"
                :style="{height: '70px'}"
              />
              <span class="text">{{ selectedRetailer.quantityReasonedEpackageRetailers }}</span>
            </div>
          </div>
        </div>

        <div class="row align--center">

          <div class="flex xs12 md4">
            <va-input
              :value="searchBrandName"
              :placeholder="$t('Search by brand name')"
              @input="searchBrand"
              class="input--border"
              removable>
              <va-icon name="fa fa-search" slot="prepend"/>
            </va-input>
          </div>

          <div class="flex xs12 md4">
            <va-input
              :value="searchEAN"
              :placeholder="$t('Search by EAN')"
              @input="onSearchEAN"
              class="input--border"
              removable>
              <va-icon name="fa fa-search" slot="prepend"/>
            </va-input>
          </div>
          <div class="flex xs12 md3 f-basis__unset ml-20">
            <va-button v-if="!allSelected" @click="selectAll()">
              Select all
            </va-button>
            <va-button v-else @click="unSelectAll()">
              Unselect all
            </va-button>
          </div>
          <div class="flex xs12 md3 f-basis__unset ml-30">
            <va-button v-if="allSelected" @click="generateFeed()">
              Generate feed
            </va-button>
          </div>
          <div class="flex xs12 md3 f-basis__unset ml-30">
            <va-button v-if="allSelected" @click="deleteSelected()">
              Delete
            </va-button>
          </div>
        </div>

        <div class="row align--center">

          <div class="flex xs12 md4">
            <va-input
              :value="searchMPN"
              :placeholder="$t('Search by MPN')"
              @input="onSearchMPN"
              class="input--border"
              removable>
              <va-icon name="fa fa-search" slot="prepend"/>
            </va-input>
          </div>
          <div class="flex xs12 md4">
            <va-input
              :value="searchSKU"
              :placeholder="$t('Search by SKU ID')"
              @input="onSearchSKU"
              class="input--border"
              removable>
              <va-icon name="fa fa-search" slot="prepend"/>
            </va-input>
          </div>
          <div class="flex xs12 md3 offset--md1">
            <va-select
              v-model="perPage"
              :label="$t('tables.perPage')"
              :options="perPageOptions"
              noClear
            />
          </div>
        </div>

        <vac-data-table
          :fields="fields"
          :data="filteredData"
          :per-page="parseInt(perPage)">
          <template slot="select" slot-scope="props">
            <va-checkbox :value="props.rowData.checked" :key="`hello${props.rowData.id} - ${props.rowData.checked}`"
                         @input="select($event, props)"/>
          </template>
          <template slot="reasonedAt" slot-scope="props">
            <div :style="{}">{{ moment(props.rowData.reasonedAt).format('HH:mm DD.MM.YYYY') }}</div>
          </template>

          <template slot="reason" slot-scope="props">
            <div v-if="props.rowData.reason === 1">Incorrect ean</div>
            <div v-else-if="props.rowData.reason === 2">No script</div>
            <div v-else-if="props.rowData.reason === 3">Incorrect script</div>
            <div v-else-if="props.rowData.reason === 4">No brand</div>
            <div v-else-if="props.rowData.reason === 5">Incorrect brand</div>
            <div v-else-if="props.rowData.reason === 6">Incorrect domain</div>
            <div v-else-if="props.rowData.reason === 7">No ean</div>
          </template>

          <template slot="reasonLink" slot-scope="props">
            <a target="_blank" :href="props.rowData.reasonLink">{{ props.rowData.reasonLink }}</a>
          </template>

          <template slot="removeReason" slot-scope="props">
            <va-button class="modal-retail__remove" @click="onRemoveButtonClick(props.rowData, props.rowIndex)">
              <IconClosePopup/>
            </va-button>
          </template>

        </vac-data-table>

      </vac-card>

    </div>

    <va-modal
      v-model="showRemoveModal"
      size="small"
      title="Confirmation"
      :message="`Are you sure you want to remove the chosen reason?`"
      :okText=" $t('modal.confirm') "
      :cancelText=" $t('modal.cancel') "
      @cancel="onRemoveCancel"
      @ok="onRemoveConfirm"
    >
      <template v-if="chosenProduct">{{ `${chosenProduct.brand.name} ${chosenProduct.product.name}` }}</template>
    </va-modal>
  </div>

</template>

<script>
import {debounce} from 'lodash';
import HelperExcel from '../../../services/Helpers/HelperExcel';
import Actions from "../../../components/actions/Actions";
import moment from 'moment'
import VacChart from "../../../components/VacChart";
import Chart from "../../../components/retail-manager/mixins/chart";
import API from "../../../services/API/API";
import {showToastError, showToastSuccess} from "../../../services/Helpers/HelperToast";
import {mapActions, mapMutations} from "vuex";
import IconClosePopup from "../../ui/icons/IconClosePopup";
import VacDataTable from "@/components/tables/VacDataTable";
import { Doughnut } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement } from 'chart.js'
import axios from "axios";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement)

export default {
  name: 'DataStats',

  props: {
    data: {
      type: Array,
      default: () => [],
    },
    selectedRetailer: {}
  },

  components: {
    Actions,
    // VacChart,
    IconClosePopup,
    VacDataTable,
    Doughnut
  },

  mixins: [Chart],

  data() {
    return {
      totalCount: 0,
      perPageOptions: ['10', '30', '50', '100'],
      perPage: '100',
      searchBrandName: '',
      searchEAN: '',
      searchMPN: '',
      searchSKU: '',
      showRemoveModal: false,
      chosenProduct: null,
    };
  },

  computed: {
    chartOption() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        cutout: '60%',
        plugins: {
          tooltip: {
            enabled: false,
            external: function(context) {
              // Tooltip Element
              let tooltipEl = document.getElementById('chartjs-tooltip');

              // Create element on first render
              if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'chartjs-tooltip';
                tooltipEl.innerHTML = '<table></table>';
                document.body.appendChild(tooltipEl);
              }

              // Hide if no tooltip
              const tooltipModel = context.tooltip;
              if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
              }

              // Set caret Position
              tooltipEl.classList.remove('above', 'below', 'no-transform');
              if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
              } else {
                tooltipEl.classList.add('no-transform');
              }

              function getBody(bodyItem) {
                return bodyItem.lines;
              }

              // Set Text
              if (tooltipModel.body) {
                const titleLines = tooltipModel.title || [];
                const bodyLines = tooltipModel.body.map(getBody);

                let innerHtml = '<thead>';

                titleLines.forEach(function(title) {
                  innerHtml += '<tr><th>' + title + '</th></tr>';
                });
                innerHtml += '</thead><tbody>';

                bodyLines.forEach(function(body, i) {
                  const colors = tooltipModel.labelColors[i];
                  let style = 'background:' + colors.backgroundColor;
                  style += '; border-color:' + colors.borderColor;
                  style += '; border-width: 2px';
                  const span = '<span style="' + style + '"></span>';
                  innerHtml += '<tr><td>' + span + body + '</td></tr>';
                });
                innerHtml += '</tbody>';

                let tableRoot = tooltipEl.querySelector('table');
                tableRoot.innerHTML = innerHtml;
              }

              const position = context.chart.canvas.getBoundingClientRect();
              // const bodyFont = Chart.helpers.toFont(tooltipModel.options.bodyFont);

              // Display, position, and set styles for font
              tooltipEl.style.opacity = 1;
              tooltipEl.style.background = 'rgba(0, 0, 0, 0.5)';
              tooltipEl.style.position = 'absolute';
              tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
              tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
              // tooltipEl.style.font = bodyFont.string;
              tooltipEl.style.fontSize = '14px';
              tooltipEl.style.color = '#FFFFFF'
              tooltipEl.style.borderRadius = '5px'
              tooltipEl.style.padding = '5px';
              tooltipEl.style.pointerEvents = 'none';
            }
          },
          legend: {
            display: false
          }
        },
        scales: {
          x: {display: false},
        },
      }
    },
    fields() {
      return [
        {
          name: '__slot:select',
          title: this.$t('Select'),
        },
        {
          name: '__slot:reasonedAt',
          sortField: 'reasonedAt',
          title: this.$t('Date'),
          width: '10%',
          dataClass: "--nowrap",
        },
        {
          name: 'retailer',
          sortField: 'retailer',
          title: this.$t('Retailer'),
          width: '10%',
        },
        {
          name: 'brand.name',
          sortField: 'brand.name',
          title: this.$t('Brand name'),
          width: '10%',
        },
        {
          name: 'product.name',
          sortField: 'product.name',
          title: this.$t('Product name'),
          width: '10%',
        },
        {
          name: 'product.ean',
          sortField: 'product.ean',
          title: this.$t('EAN'),
          width: '10%',
        },
        {
          name: 'product.mpn',
          sortField: 'product.mpn',
          title: this.$t('MPN'),
          width: '10%',
        },
        {
          name: 'skuId',
          sortField: 'skuId',
          title: this.$t('SKU id'),
          width: '10%',
        },
        {
          name: '__slot:reason',
          sortField: 'reason',
          title: this.$t('Reason'),
          width: '10%',
        },
        {
          name: '__slot:reasonLink',
          sortField: 'reasonLink',
          title: this.$t('Reason Link'),
        },
        {
          name: '__slot:removeReason',
          title: this.$t('Remove Reason'),
        }
      ];
    },

    filteredData() {
      let data = this.data;

      if (this.searchBrandName.length) {
        data = data.filter(item => {
          return item.brand.name.toLowerCase().includes(this.searchBrandName.toLowerCase());
        });
      }

      if (this.searchEAN.length) {
        data = data.filter(item => {
          return item.product.ean.toLowerCase().includes(this.searchEAN.toLowerCase());
        });
      }

      if (this.searchMPN.length) {
        data = data.filter(item => {
          return item.product.mpn.toLowerCase().includes(this.searchMPN.toLowerCase());
        });
      }

      if (this.searchSKU.length) {
        data = data.filter(item => {
          return item.skuId.toLowerCase().includes(this.searchSKU.toLowerCase());
        });
      }

      this.updateTotalCount(data.length);
      return data;
    },
     allSelected() {
      let selected = false
       this.data.forEach(d => {
         if (d.checked) {
           selected = true
         }
       })
       return selected
     }
  },

  methods: {
    moment,

    ...mapActions(['getRetailManagerLicenses', 'generateOzonFeed']),
    ...mapMutations(['removeRetailManagerLicenseReasonById']),

    selectAll() {
      let data = JSON.parse(JSON.stringify(this.data));

      data.forEach(d => {
        d.checked = true
      })

      this.$emit('updateData', data)
    },

    unSelectAll() {
      let data = JSON.parse(JSON.stringify(this.data));

      data.forEach(d => {
        d.checked = false
      })

      this.$emit('updateData', data)
    },

    generateFeed() {
      let uids = []
      this.data.forEach(d => {
        if (d.checked) {
          uids.push(d.skuId)
        }
      })
      API.APIPost(`${process.env.VUE_APP_API_URL}/retailer-group/ozon-jsons-generate-by-skus`, { skuIds: uids }, () => {
        showToastSuccess('Your feed was generated on the panel', this.$toast);
        this.unSelectAll()
      }, () => {
        showToastError('There was an error while generating feed', this.$toast);
        this.chosenProduct = null
      })
    },
    deleteSelected() {
      let epackageRetailers = []
      let selected = []
      this.data.forEach(d => {
        if (d.checked) {
          epackageRetailers.push({
            epackageId: d.epackageId,
            retailerId: d.retailerId
          })
          selected.push(d)
        }
      })
      axios({
        url: `${process.env.VUE_APP_API_URL}/epackage/clear-reasons`,
        method: "DELETE",
        data: { epackageRetailers },
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`
        }
      }).then(() => {
        showToastSuccess('The reasons were removed', this.$toast);
        selected.forEach(s => {
          this.removeRetailManagerLicenseReasonById({id: s.id, retailerId: s.retailerId})
        })
        this.chosenProduct = null
      }).catch(() => {
        showToastError('There was an error while removing the reasons', this.$toast);
        this.chosenProduct = null
      })
    },
    onRemoveButtonClick(data) {
      this.chosenProduct = data
      this.showRemoveModal = true
    },

    onRemoveCancel() {
      this.showRemoveModal = false
      this.chosenProduct = null
    },

    onRemoveConfirm() {
      this.showRemoveModal = false
      API.APIDelete(`${process.env.VUE_APP_API_URL}/epackage/${this.chosenProduct.epackageId}/${this.chosenProduct.retailerId}/reason`, {}, () => {
        showToastSuccess('The reason was removed', this.$toast);
        this.removeRetailManagerLicenseReasonById({id: this.chosenProduct.id, retailerId: this.selectedRetailer.id})
        this.chosenProduct = null
      }, () => {
        showToastError('There was an error while removing the reason', this.$toast);
        this.chosenProduct = null
      })
    },
    updateTotalCount(value) {
      this.totalCount = value;
    },

    exportToExcel() {
      const labels = ["Date", "Retailer", "Brand Name", "Product Name", "EAN", "MPN", "SKU ID", 'Reason', 'Reason Link'];
      const data = [];
      const columnWidth = [];

      const reasons = {
        '1': 'Incorrect ean',
        '2': 'No script',
        '3': 'Incorrect script',
        '4': 'No brand',
        '5': 'Incorrect brand',
        '6': 'Incorrect domain',
        '7': 'No ean'
      }

      this.filteredData.forEach(info => {
        const obj = {
          "Date": moment(info.reasonedAt).format('HH:mm DD.MM.YYYY'),
          "Retailer": info.retailer,
          "Brand Name": info.brand.name,
          "Product Name": info.product.name,
          "EAN": info.product.ean,
          "MPN": info.product.mpn,
          "SKU ID": info.skuId,
          "Reason": reasons[info.reason],
          "Reason Link": info.reasonLink,
        };
        data.push(obj);
      });

      labels.forEach(() => {
        columnWidth.push(16);
      });

      HelperExcel.exportToExcel({}, 'Problems', labels, data, columnWidth);
    },

    select(val, row) {
      let data = JSON.parse(JSON.stringify(this.data));

      let selectedItem = data.find(d => {
        return d.id === row.rowData.id
      })

      selectedItem.checked = !selectedItem.checked
      this.$emit('updateData', data)
    },

    searchBrand: debounce(function (value) {
      this.searchBrandName = value;
    }, 400),

    onSearchEAN: debounce(function (value) {
      this.searchEAN = value;
    }, 400),

    onSearchMPN: debounce(function (value) {
      this.searchMPN = value;
    }, 400),

    onSearchSKU: debounce(function (value) {
      this.searchSKU = value;
    }, 400),
  },

};
</script>

<style lang="scss" scoped>
.f-basis__unset {
  flex-basis: unset !important;
}
.w100 {
  width: 100%;
}

.modal-retail {
  &__remove {
    padding: 5px;
  }
}

.cards {
  &__percentage {
    width: 70px;
    height: 70px;
    z-index: 1;
    position: relative;
    margin-left: 0;

    .text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 16px;
    }
  }
}
.ml-30 {
  margin-left: 30px;
}
.ml-20 {
  margin-left: 20px;
}
</style>
